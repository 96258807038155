import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Button from "../components/Button"
import Container from "../components/Container"
import Doom from "../components/Doom"
import Img from "../components/Img"
import Markdown from "../components/Markdown"
import Section from "../components/Section"
import Site from "../components/Site"
import "../css/give.scss"

const GivePage = () => (
  <StaticQuery
    render={data => {
      const giveData = data.allDatoCmsGive.edges[0].node

      return (
        <Site
          title     = "Give"
          className = "hcc-give"
        >
          <article>
            <Section className="hcc-give-intro">
              <Container className="hcc-grid is-content">
                <header>
                  <Doom tag="h1" content={giveData.title}/>
                  <Doom tag="p" content={giveData.intro}/>
                  <Button label={giveData.buttonLabel} href={giveData.buttonLink} target="_blank" rel="noopener noreferrer" className="is-primary has-no-outline"/>
                </header>
              </Container>
              <div className="hcc-grid is-ornamental">
                <div className="is-01"><Img src="/give/hero-01.jpg" alt="Image" width={1100} height={1100}/></div>
                <div className="is-02"><Img src="/give/hero-02.jpg" alt="Image" width={1100} height={1100}/></div>
              </div>
            </Section>
            {/*
            <Hero
              type      = "bubble"
              className = "hcc-give-intro"
              bg        = {[
                {
                  opacity : 0.66,
                  stack   : [
                    { angle : 'to bottom right', stops : ['#111', '#111'] },
                  ]
                },
                {
                  opacity : 0.22,
                  stack   : [
                    { angle : 'to bottom right', stops : ['rgb(129, 59, 198)', 'rgb(255, 208, 193)'] },
                  ]
                },
                {
                  filter : 'contrast(0.8) saturate(1.5)',
                  blend  : 'lighten',
                  stack  : [
                    { url : '/give/hero-02.jpg', position : '95% 50%' },
                    { angle : 'to bottom right', stops : ['rgb(1, 117, 175)', 'rgb(183, 33, 88)'] },
                  ]
                },
              ]}
            >
              <h1><span>{giveData.title}</span></h1>
              <p>{giveData.intro}</p>
              <div className="hcc-hero-cta break-sm">
                <Button label={giveData.buttonLabel} href={giveData.buttonLink} target="_blank" rel="noopener noreferrer" className="is-primary has-no-outline"/>
              </div>
            </Hero>
            */}
            <Section className="hcc-give-content">
              <Container>
                <Markdown
                  tag    = "div"
                  type   = {null}
                  source = {giveData.content}
                />
              </Container>
            </Section>
          </article>
        </Site>
      )
    }}
    query={graphql`
      query GiveQuery {
        allDatoCmsGive {
          edges {
            node {
              title
              intro
              buttonLabel
              buttonLink
              content
            }
          }
        }
      }
    `}
  />
)

export default GivePage
